import { howItWorksData } from './constants';
import styles from './HowItWorksList.module.scss';

export const HowItWorksList = () => {
  return (
    <ul className={styles.list}>
      {howItWorksData.map(({ animation, title, description }, index) => (
        <li key={index} className={styles.item}>
          {animation}
          <div className={styles.title}>{title}</div>
          <div className={styles.description}>{description}</div>
        </li>
      ))}
    </ul>
  );
};

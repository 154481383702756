// import { API_ENDPOINTS } from '@/src/app/api/contants';
import { InitialData } from '@/src/app/api/types';
// import { testnetChainIds } from '@/src/app/blockchain/constanst';
import { formatNumber } from '@/src/app/utils/formatNumber';
import { Variants } from 'framer-motion';
import { useMemo } from 'react';
// import useSWR from 'swr';
import { AnimationFlask } from '../../AnimationFlask/AnimationFlask';
import { AnimationOrbits } from '../../AnimationOrbit/AnimationOrbit';
import { AnimationPuddle } from '../../AnimationPuddle/AnimationPuddle';
import { animationFlaskVariants, animationOrbitVariants, animationPuddleVariants } from '../constant';
import { usePotionPoolRewardsAddress } from './usePotionPoolRewardsAddress';
import { usePotionTotalRewards } from './usePotionTotalRewards';
export const useMetricsData = (initialData: InitialData) => {
  // const { data: yelData } = useSWR<PriceData>(API_ENDPOINTS.YEL_PRICE, {
  //   fallbackData: initialData.yelPrice
  // });

  // const { data: rewardsData } = useSWR<RewardData[]>(API_ENDPOINTS.REWARDS, {
  //   fallbackData: initialData.rewards
  // });

  // const { data: assetsData } = useSWR<AssetsData[]>(API_ENDPOINTS.ASSETS, {
  //   fallbackData: initialData.assets
  // });

  const { assets: assetsData } = initialData || {};

  const { potionsWithPoolRewardsAddress } = usePotionPoolRewardsAddress(assetsData ?? []);

  const { potionsWithRewards } = usePotionTotalRewards(potionsWithPoolRewardsAddress ?? []);

  const totalRewards: string | null = useMemo(() => {
    if (potionsWithRewards?.length === 0) {
      return null;
    }
    const rewards: number = potionsWithRewards?.reduce((acc, item) => {
      if (item) {
        const { totalRewards } = item;
        return acc + (totalRewards ?? 0);
      }
      return acc;
    }, 0);

    return formatNumber(rewards * 2);
  }, [potionsWithRewards]);

  const countChains = useMemo(() => (assetsData ? new Set(assetsData.map((item) => item?.chainId))?.size : null), [assetsData]);

  const deployedVaults: number | null = useMemo(() => (Array.isArray(assetsData) ? assetsData?.length : null), [assetsData]);

  // const yelRewards: string | null = useMemo(() => {
  //   if (rewardsData?.length === 0) {
  //     return null;
  //   }
  //   const reducedRewards: number | null =
  //     rewardsData?.reduce(
  //       (acc, { rewards, chain }) => acc + (rewards && !testnetChainIds.includes(chain) ? rewards : 0),
  //       0
  //     ) ?? null;
  //   return typeof reducedRewards === 'number' ? formatNumber(reducedRewards) : null;
  // }, [rewardsData]);

  const metricsData: { animation: JSX.Element; title: string; value: string | number | null; variants: Variants }[] = useMemo(() => [
    { animation: <AnimationOrbits />, title: 'Chains connected', value: countChains, variants: animationOrbitVariants },
    {
      animation: <AnimationFlask />,
      title: 'Deployed Vaults',
      value: deployedVaults,
      variants: animationFlaskVariants
    },
    {
      animation: <AnimationPuddle />,
      title: 'Total earned',
      value: totalRewards,
      variants: animationPuddleVariants
    }
  ], [countChains, deployedVaults, totalRewards]);

  return { metricsData };
};

import { Variants } from "framer-motion";

export const wrapperVariants: Variants = {
  visible: {
    transition: {
      delayChildren: 0.3,
      staggerChildren: 0.3
    }
  }
};

export const firstCurrencyVariants: Variants = {
  hidden: { opacity: 0, x: -30 },
  visible: { opacity: 1, x: 0, transition: { duration: 0.3 } }
};

export const secondCurrencyVariants: Variants = {
  hidden: { opacity: 0, x: 30 },
  visible: { opacity: 1, x: 0, transition: { duration: 0.3 } }
};

export const tradeArrowsIconVariants: Variants = {
  hidden: { opacity: 0, rotate: -90, scale: 0.6 },
  visible: { opacity: 1, rotate: 0, scale: 1, transition: { duration: 0.3 } }
};

import { sonic, blast, base, fantom, berachain } from 'viem/chains';
import { createPublicClient, fallback, http } from 'viem';

export const SUPPORTED_CHAINS = [sonic, blast, base, fantom, berachain] as const;

export const clients  = {
  [blast.id]: createPublicClient({
    chain: blast,
    transport: fallback([
      http('https://rpc.ankr.com/blast'),
      http('https://blast.blockpi.network/v1/rpc/public'),
      http('https://blast.drpc.org')
    ])
  }),
  [base.id]: createPublicClient({
    chain: base,
    transport: fallback([
      http('https://mainnet.base.org'),
      http('https://base.llamarpc.com'),
      http('https://base-rpc.publicnode.com')
    ])
  }),
  [sonic.id]: createPublicClient({
    chain: sonic,
    transport: fallback([
      http('https://rpc.soniclabs.com'),
      http('https://rpc.ankr.com/sonic_mainnet'),
      http('https://sonic.drpc.org'),
      http('https://sonic-rpc.publicnode.com')
    ])
  }),
  [fantom.id]: createPublicClient({
    chain: fantom,
    transport: fallback([
      http('https://rpc.ftm.tools/'),
      http('https://rpc.ankr.com/fantom'),
      http('https://fantom-mainnet.public.blastapi.io')
    ])
  }),
  [berachain.id]: createPublicClient({
    chain: berachain,
    transport: http('https://rpc.berachain.com')
  })
};

import { CommonLink } from '../../components/CommonLink/CommonLink';
import { CommonLinkSizeVariants, CommonLinkVariants } from '../../components/CommonLink/types';
import { SectionTitle } from '../../components/SectionTitle/SectionTitle';
import { Links } from '../../constants/links';
import DropsIcon from '@/public/drops.svg';
import { BackgroundAnimation } from './components/BackgroundAnimation/BackgroundAnimation';
import { VaultList } from './components/VaultList/VaultList';

import styles from './Vaults.module.scss';
import { InitialData } from '../../api/types';

export const Vaults = ({ initialData }: { initialData: InitialData }) => {
  return (
    <section className={`${styles.container} container`}>
      <BackgroundAnimation />
      <DropsIcon className={styles.dropsIcon} />
      <div className={styles.wrapper}>
        <div className={styles.titleWrapper}>
          <SectionTitle>Top Vaults</SectionTitle>
        </div>
        <VaultList initialData={initialData} />
        <CommonLink
          link={Links.APP}
          variant={CommonLinkVariants.OUTLINE}
          size={CommonLinkSizeVariants.BIG}
          className={styles.link}
        >
          Earn now
        </CommonLink>
      </div>
    </section>
  );
};

// import { API_ENDPOINTS } from '@/src/app/api/contants';
import { InitialData } from '@/src/app/api/types';
// import { testnetChainIds } from '@/src/app/blockchain/constanst';
import { formatNumber } from '@/src/app/utils/formatNumber';
import { useMemo } from 'react';
// import useSWR from 'swr';

export const useInfoData = (initialData: InitialData) => {
  // const { data: tvls } = useSWR<TvlData[]>(API_ENDPOINTS.TVL, {
  //   fallbackData: initialData.tvls
  // });

  // const { data: aprs } = useSWR<AprData[]>(API_ENDPOINTS.APR, {
  //   fallbackData: initialData.aprs
  // });

  const { aprs, tvls } = initialData || {};

  const tvl: string | null = useMemo(() => {
    if (tvls?.length === 0) {
      return null;
    }
    const reducedTvl: number | null =
      tvls?.reduce((acc, item) => {
        if (item) {
          const { chainId, tvl, lptvl } = item;

          if (typeof chainId === 'number' && typeof tvl === 'number' && typeof lptvl === 'number') {
            return acc + (lptvl ? tvl + lptvl : 0);
          }
        }
        return acc;
      }, 0) ?? null;
    return typeof reducedTvl === 'number' ? formatNumber(reducedTvl) : null;
  }, [tvls]);

  const bestApr: string | null = useMemo(() => {
    if (aprs?.length === 0) {
      return null;
    }
    const sertedData: number | null = aprs?.sort((a, b) => b.holderapr! - a.holderapr!)?.[0]?.holderapr ?? null;
    return typeof sertedData === 'number' ? formatNumber(sertedData, 2) : null;
  }, [aprs]);

  const avrLpApr: string | null = useMemo(() => {
    if (aprs?.length === 0) {
      return null;
    }
    const reduceLpApr: number | null = aprs?.reduce((acc, { lpapr }) => acc + (lpapr ?? 0), 0) ?? null;

    return typeof reduceLpApr === 'number' && aprs ? formatNumber(reduceLpApr / aprs.length, 2) : null;
  }, [aprs]);

  const infoData = [
    { title: 'TVL', value: tvl ? `USD ${tvl}` : null },
    { title: 'best APR', value: bestApr ? `${bestApr}%` : null },
    { title: 'Average LP APR', value: avrLpApr ? `${avrLpApr}%` : null }
  ];

  return { infoData };
};

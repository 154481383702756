import { WrapAnimation } from '../WrapAnimation/WrapAnimation';
import { ProvideAnimation } from '../ProvideAnimation/ProvideAnimation';
import { TradeAnimation } from '../TradeAnimation/TradeAnimation';

export const howItWorksData = [
  {
    animation: <WrapAnimation />,
    title: 'Wrap tokens',
    description: `Use your tokens to wrap into any Potion and receive liquid tokens in return, allowing you to participate in Holders' APR.`
  },
  {
    animation: <ProvideAnimation />,
    title: 'Provide LPs',
    description: `Use your liquid tokens to create an LP, either with tokens or liquid tokens as a pair. Then, stake it to receive the LP APR from Potion.`
  },
  {
    animation: <TradeAnimation />,
    title: 'Trade on WrapMarket',
    description: `Use the WrapMarket to identify and capitalize on arbitrage opportunities. Seamlessly wrap into any Potion using a variety of tokens or liquid tokens.`
  }
];

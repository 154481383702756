// import { API_ENDPOINTS } from '@/src/app/api/contants';
import { InitialData } from '@/src/app/api/types';
// import { testnetChainIds } from '@/src/app/blockchain/constanst';
import { useMemo } from 'react';
// import useSWR from 'swr';
import { colors, stub } from './constants';

export const useVaultsData = (initialData: InitialData) => {
  // const { data: aprs } = useSWR<AprData[]>(API_ENDPOINTS.APR, {
  //   fallbackData: initialData.aprs
  // });

  const { aprs, assets } = initialData || {};

  const filteredAprs = useMemo(() => {
    if (aprs?.length === 0 || !aprs) {
      return stub;
    }
    return aprs
      ?.sort((a, b) => b.holderapr! - a.holderapr!)
      ?.slice(0, 3)
      .map((item, index) => {
        const foundAsset =
          assets?.find((asset) => asset?.address?.toLowerCase() === item?.address?.toLowerCase()) ?? [];
        return { ...item, ...foundAsset, color: colors[index], title: 'APR' };
      });
  }, [aprs, assets]);
  return { filteredAprs };
};

import { InitialData } from '../../api/types';
import { SectionTitle } from '../../components/SectionTitle/SectionTitle';
import { MetricList } from './components/MetricList/MetricList';
import styles from './Metrics.module.scss';

export const Metrics = ({ initialData }: { initialData: InitialData }) => {
  return (
    <section className={`${styles.container} container`}>
      <div className={styles.titleWrapper}>
        <SectionTitle>Metrics</SectionTitle>
      </div>
      <MetricList initialData={initialData} />
    </section>
  );
};


export const BASE_URL = process.env.NEXT_PUBLIC_API_BASE_URL;
export const PUBLIC_URL = 'https://yel.finance';

export const API_ENDPOINTS = {
  TVL: `${BASE_URL}/tvl`,
  APR: `${BASE_URL}/apr`,
  ASSETS: `${BASE_URL}/assets`,
  REWARDS: `${BASE_URL}/rewards/sl/distributed/all`,
  POTION_PRICE: `${BASE_URL}/price/potion`,
};

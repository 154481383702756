'use client';
import useSWR, { BareFetcher } from 'swr';
import { ExtendsAssetsData } from '@/src/app/api/types';
import { getPotionPoolRewardsAddress } from '../utils/getPotionPoolRewardsAddress';
import { Address } from 'viem';
import { useMemo } from 'react';

const fetcher: BareFetcher<Promise<Address | undefined>> = async (asset: ExtendsAssetsData) => {
  return getPotionPoolRewardsAddress(asset);
};

export function usePotionPoolRewardsAddress(assets: ExtendsAssetsData[]): {
  potionsWithPoolRewardsAddress: ExtendsAssetsData[];
  isLoadingPoolRewardsAddress: boolean;
} {
  const { data, error, isLoading } = useSWR(
    ['potionPoolRewardsAddress', JSON.stringify(assets)],
    async () => {
      const newPotions = await Promise.all(
        assets.map(async (asset) => {
          const address = await fetcher(asset);
          return { ...asset, poolRewardsAddress: address };
        })
      );
      return newPotions;
    },
    {
      revalidateOnMount: true
    }
  );

  if (error) {
    console.error(error);
  }

  return useMemo(
    () => ({
      potionsWithPoolRewardsAddress: data ?? [],
      isLoadingPoolRewardsAddress: isLoading
    }),
    [data, isLoading]
  );
}

import { ExtendsAssetsData } from '@/src/app/api/types';
import tokenRewardsAbi from '@/src/app/blockchain/abis/tokenRewardsAbi.json';
import { clients } from '@/src/app/blockchain/config';
import { formatUnits } from 'viem';

export const getPotionTotalRewards = async (asset: ExtendsAssetsData): Promise<number | undefined> => {
  let value: number | undefined;
  try {
    const { chainId, decimals, poolRewardsAddress } = asset;
    if (poolRewardsAddress && chainId) {
      const rewardsDistributed = (await clients[chainId].readContract( {
        functionName: 'rewardsDistributed',
        abi: tokenRewardsAbi,
        address: poolRewardsAddress
      })) as bigint | undefined;

      if (rewardsDistributed) {
        value = +formatUnits(rewardsDistributed, decimals ?? 18);
      }
    }
  } catch (e) {
    console.error(e);
  }
  return value;
};

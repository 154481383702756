import { CommonLink } from '../../components/CommonLink/CommonLink';
import { CommonLinkSizeVariants, CommonLinkVariants } from '../../components/CommonLink/types';
import { SectionTitle } from '../../components/SectionTitle/SectionTitle';
import { Links } from '../../constants/links';
import { HowItWorksList } from './components/HowItWorksList/HowItWorksList';
import styles from './HowItWorks.module.scss';

export const HowItWorks = () => {
  return (
    <section className={`${styles.container} container`}>
      <SectionTitle>How it works</SectionTitle>
      <HowItWorksList />
      <CommonLink
        variant={CommonLinkVariants.SOLID}
        size={CommonLinkSizeVariants.BIG}
        isBlank
        link={Links.DOCS}
        className={styles.link}
      >
        Read docs
      </CommonLink>
    </section>
  );
};

import { Variants } from "framer-motion";

export const wrapperVariants: Variants = {
  visible: {
    transition: {
      delayChildren: 0.5,
      staggerChildren: 0.5
    }
  }
};

export const firstCurrencyVariants: Variants = {
  hidden: { opacity: 0, x: -30 },
  visible: { opacity: 1, x: 0, transition: { duration: 0.3 } }
};

export const secondCurrencyVariants: Variants = {
  hidden: { opacity: 0, x: 30 },
  visible: { opacity: 1, x: 0, transition: { duration: 0.3 } }
};

export const returnIconVariants: Variants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { duration: 0.3 } }
};

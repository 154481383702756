import(/* webpackMode: "eager" */ "/vercel/path0/public/icons/currency/arbitrum.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/icons/currency/avax.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/icons/currency/base.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/icons/currency/bera.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/icons/currency/binance.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/icons/currency/blast.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/icons/currency/ftm.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/icons/currency/optimism.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/icons/currency/polygon.svg");
;
import(/* webpackMode: "eager", webpackExports: ["DynamicSections"] */ "/vercel/path0/src/app/components/DynamicSections/DynamicSections.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/sections/Chains/Chains.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["CurrencyAnimation"] */ "/vercel/path0/src/app/sections/Chains/components/CurrencyAnimation/CurrencyAnimation.tsx");

'use client';
import PoolMobile from '@/public/poolMobile.svg';
import { useEffect, useMemo, useState } from 'react';
import useSWR from 'swr';
import { API_ENDPOINTS } from '../../api/contants';
import { getHolderAPRByDeployDate } from '../../api/getHolderAPRByDeployDate';
import { getPairedLPToken } from '../../api/getPairedLPToken';
import { AprData, AssetsData, InitialData, TvlData } from '../../api/types';
import { Hero } from '../../sections/Hero/Hero';
import { HowItWorks } from '../../sections/HowItWorks/HowItWorks';
import { Metrics } from '../../sections/Metrics/Metrics';
import { Vaults } from '../../sections/Vaults/Vaults';
import styles from './DynamicSections.module.scss';
import { closedChainIds, testnetChainIds } from '../../blockchain/constanst';

interface IDynamicSectionsProps {
  initialData: InitialData;
}

export const DynamicSections = ({ initialData }: IDynamicSectionsProps) => {
  const { data: assetsData } = useSWR<AssetsData[]>(API_ENDPOINTS.ASSETS, {
    fallbackData: initialData.assets
  });

  const { data: tvls } = useSWR<TvlData[]>(API_ENDPOINTS.TVL, {
    fallbackData: initialData.tvls
  });

  const { data: aprs } = useSWR<AprData[]>(API_ENDPOINTS.APR, {
    revalidateOnMount: true
  });

  const filteredAssets: AssetsData[] | undefined = useMemo(() => {
    return assetsData?.filter(
      (asset) =>
        !testnetChainIds.includes(asset?.chainId ?? 0) &&
        !closedChainIds.includes(asset?.chainId ?? 0) &&
        !asset?.isHidden &&
        asset?.type === 'potion'
    );
  }, [assetsData]);

  const [formatedPotionArps, setFormatedPotionArps] = useState<AprData[] | undefined>();

  useEffect(() => {
    if (!aprs) return;

    const formatAprs = async () => {
      const results = await Promise.allSettled(
        aprs
          .filter((apr) => {
            const asset: AssetsData | undefined = filteredAssets?.find(
              (asset) => asset?.address?.toLowerCase() === apr?.address?.toLowerCase() && apr?.chainId === asset.chainId
            );

            return !!asset;
          })
          .map(async (apr) => {
            try {
              const { chainId, holderapr, lpapr, address } = apr;
              let calculateApr = holderapr ?? 0;

              let calculateLpApr = lpapr ?? 0;

              if (!holderapr || !lpapr) {
                calculateApr = await getHolderAPRByDeployDate({ potionAddress: address, chainId });

                const pairedLPToken = await getPairedLPToken({ chainId, potionAddress: address });
                const isPotionPairedLPToken: boolean = !!filteredAssets?.find(
                  (asset) => asset.chainId === chainId && asset.address?.toLowerCase() === pairedLPToken?.toLowerCase()
                );

                const pairedLPTokenApr = isPotionPairedLPToken
                  ? await getHolderAPRByDeployDate({ potionAddress: pairedLPToken, chainId })
                  : 0;

                calculateLpApr = pairedLPTokenApr && calculateApr ? (calculateApr + pairedLPTokenApr) / 2 : 0;
              }

              return { ...apr, holderapr: calculateApr, lpapr: calculateLpApr };
            } catch (error) {
              console.error('Error processing APR:', error);
              return null;
            }
          })
      );

      setFormatedPotionArps(
        //@ts-expect-error TODO fix
        results.filter((result) => result.status === 'fulfilled' && result.value).map((result) => result.value)
      );
    };

    formatAprs();
  }, [aprs, filteredAssets]);

  const filterdTvls: TvlData[] | undefined = useMemo(() => {
    return tvls?.filter((tvl) => {
      return !!filteredAssets?.find(
        (asset) => asset?.address?.toLowerCase() === tvl?.address?.toLowerCase() && tvl?.chainId === asset.chainId
      );
    });
  }, [tvls, filteredAssets]);

  const formatedInitialData: InitialData = useMemo(() => {
    return {
      assets: filteredAssets,
      tvls: filterdTvls,
      aprs: formatedPotionArps
    };
  }, [filterdTvls, filteredAssets, formatedPotionArps]);

  return (
    <div>
      <Hero initialData={formatedInitialData} />
      <div className={`${styles.container}`}>
        <PoolMobile className={styles.poolMobile} />
        <Vaults initialData={formatedInitialData} />
        <Metrics initialData={formatedInitialData} />
        <HowItWorks />
      </div>
    </div>
  );
};

import { AssetsData } from '@/src/app/api/types';
import potionAbi from '@/src/app/blockchain/abis/potion.json';
import stakingPoolAbi from '@/src/app/blockchain/abis/stakingPool.json';
import { clients } from '@/src/app/blockchain/config';
import { Address } from 'viem';

export const getPotionPoolRewardsAddress = async (asset: AssetsData): Promise<Address | undefined> => {
  try {
    const { chainId, address: potionAddress } = asset;

    if (!potionAddress || !chainId) return;

    const lpStakingPoolAddress = (await clients[chainId].readContract({
      functionName: 'lpStakingPool',
      address: potionAddress,
      abi: potionAbi
    })) as Address | undefined;

    if (lpStakingPoolAddress) {
      return (await clients[chainId].readContract({
        functionName: 'poolRewards',
        abi: stakingPoolAbi,
        address: lpStakingPoolAddress
      })) as Address | undefined;
    }
  } catch (e) {
    console.error(e);
  }
};

import { Address } from 'viem';
import { Chains } from '../blockchain/types';
import { clients } from '../blockchain/config';
import potionAbi from '@/src/app/blockchain/abis/potion.json';

export interface IGetPairedLPTokenArgs {
  chainId?: Chains;
  potionAddress?: Address;
}

export const getPairedLPToken = async ({
  chainId,
  potionAddress
}: IGetPairedLPTokenArgs): Promise<Address | undefined> => {
  if (!chainId || !potionAddress) return;
  try {
    const pairedLPToken = (await clients[chainId]?.readContract({
      functionName: 'PAIRED_LP_TOKEN',
      address: potionAddress,
      abi: potionAbi
    })) as Address | undefined;
    return pairedLPToken;
  } catch (e) {
    console.error(e);
  }
};

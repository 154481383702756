import useSWR, { BareFetcher } from 'swr';
import { getPotionTotalRewards } from '../utils/getPotionTotalRewards';
import { ExtendsAssetsData } from '@/src/app/api/types';

const fetcher: BareFetcher<Promise<number | undefined>> = async (asset: ExtendsAssetsData) => {
  return getPotionTotalRewards(asset);
};

export function usePotionTotalRewards(assets: ExtendsAssetsData[]): {
  potionsWithRewards: ExtendsAssetsData[];
  isLoadingRewards: boolean;
} {
  const { data, error, isLoading } = useSWR(
    ['potionTotalRewards', JSON.stringify(assets)],
    async () => {
      const newPotions = await Promise.all(
        assets.map(async (asset) => {
          const rewards = await fetcher(asset);
          return { ...asset, totalRewards: rewards };
        })
      );
      return newPotions;
    },
    {
      revalidateOnMount: true
    }
  );

  if (error) {
    console.error(error);
  }

  return {
    potionsWithRewards: data ?? [],
    isLoadingRewards: isLoading
  };
}

import { Address } from 'viem';
import { clients } from '../blockchain/config';
import { fetchData } from '../utils/fetchData';
import { API_ENDPOINTS } from './contants';
import { TokenPrice } from './types';
import potionAbi from '@/src/app/blockchain/abis/potion.json';
import BigNumber from 'bignumber.js';
import { Chains } from '../blockchain/types';

export interface IGetHolderAPRByDeployDateArgs {
  chainId?: Chains;
  potionAddress?: Address;
}

export const getHolderAPRByDeployDate = async ({
  chainId,
  potionAddress
}: IGetHolderAPRByDeployDateArgs): Promise<number> => {
  if (!chainId || !potionAddress) return 0;

  const SECONDS_YEAR: number = 31_536_000;

  try {
    const price: TokenPrice = await fetchData(`${API_ENDPOINTS.POTION_PRICE}/${chainId}/${potionAddress}`);
    const cbr: number | undefined = price?.cbr;

    const created = (await clients[chainId].readContract({
      functionName: 'created',
      address: potionAddress,
      abi: potionAbi
    })) as bigint | undefined;

    if (typeof created !== 'bigint') return 0;

    const currentTimestampIsSec: BigNumber = BigNumber(Math.floor(Date.now() / 1000));
    const timeAfterDeploy: BigNumber = currentTimestampIsSec.minus(BigNumber(Number(created)));

    if (!cbr) return 0;

    const differenceBtwBR: number = cbr - 1;
    const brPerSecond: number = BigNumber(differenceBtwBR).div(timeAfterDeploy).toNumber();
    const apr: number = brPerSecond * SECONDS_YEAR * 100;
    return apr;
  } catch (e) {
    console.error(e);
    return 0;
  }
};
